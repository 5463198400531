<template>
  <div class="dropzone" role="region" aria-label="File upload area">
    <div class="dz-message">
      <button type="button" class="dz-button">
        Drop files here or click to upload
        <br><small>
          Accepted files: Images, PDF, Excel, Word, ZIP, HEIC, WebP (Max 50MB)
          <br>Up to 15 files allowed
        </small>
      </button>
    </div>
    <div v-for="file in files" :key="file.upload.uuid" class="dz-preview dz-file-preview">
      <div class="dz-image"><img :src="file.dataURL" /></div>
      <div class="dz-details">
        <div class="dz-size">{{ formatFileSize(file.size) }}</div>
        <div class="dz-filename">{{ file.name }}</div>
      </div>
      <div class="dz-progress"><span class="dz-upload" :style="{ width: file.upload.progress + '%' }"></span></div>
      <div v-if="file.status === 'error'">
        <div class="dz-error-message">{{ file.errorMessage }}</div>
        <div class="dz-error-mark">✗</div>
      </div>
      <div v-if="file.status === 'success'" class="dz-success-mark">✓</div>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';

export default {
  name: 'Dropzone',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      files: [],
    };
  },
  mounted() {
    this.initDropzone();
  },
  methods: {
    initDropzone() {
      Dropzone.autoDiscover = false;

      const defaultOptions = {
        url: '#',
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        previewsContainer: '.dropzone',
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxFilesize: 50,
        acceptedFiles: 'image/*,application/pdf,.xlsx,.xls,.doc,.docx,.zip,.heic,.webp',
        maxFiles: 5,
      };

      const finalOptions = { ...defaultOptions, ...this.options };

      this.dropzoneInstance = new Dropzone(this.$el, finalOptions);

      this.dropzoneInstance.on('addedfile', file => {
        console.log(file.name, 'file name');
        console.log(file.size, 'file size');
        console.log(file.upload.progress, 'file upload progress');

        this.files.push(file);
        this.$emit('change', this.files);
      });

      this.dropzoneInstance.on('removedfile', file => {
        const index = this.files.findIndex(f => f.upload.uuid === file.upload.uuid);
        if (index !== -1) {
          this.files.splice(index, 1);
        }
        this.$emit('change', this.files);
      });

      ['thumbnail', 'complete'].forEach(event => {
        this.dropzoneInstance.on(event, () => {
          this.$emit('change', this.files);
        });
      });

      this.dropzoneInstance.on('error', (file, errorMessage) => {
        file.errorMessage = errorMessage;
        this.$emit('error', { file, errorMessage });
      });

      this.dropzoneInstance.on('maxfilesexceeded', (file) => {
        this.$emit('error', { file, errorMessage: 'Maximum number of files exceeded' });
        this.dropzoneInstance.removeFile(file);
      });
    },
    
    getFiles() {
      return this.files;
    },
    
    removeAllFiles() {
      this.dropzoneInstance.removeAllFiles(true);
      this.files = [];
    },

    formatFileSize(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
  },
};
</script>

<style scoped>
.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.dz-preview {
  display: inline-block;
  margin: 10px;
}

.dz-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dz-progress {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  margin-top: 10px;
}

.dz-upload {
  display: block;
  height: 100%;
  background-color: #4caf50;
}

/* Add focus styles for accessibility */
.dropzone:focus-within {
  outline: 2px solid #4caf50;
}

.dz-button small {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  color: #666;
}

.dz-preview.dz-file-preview {
  display: none;
}
</style>