var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropzone",
      attrs: { role: "region", "aria-label": "File upload area" },
    },
    [
      _vm._m(0),
      _vm._l(_vm.files, function (file) {
        return _c(
          "div",
          { key: file.upload.uuid, staticClass: "dz-preview dz-file-preview" },
          [
            _c("div", { staticClass: "dz-image" }, [
              _c("img", { attrs: { src: file.dataURL } }),
            ]),
            _c("div", { staticClass: "dz-details" }, [
              _c("div", { staticClass: "dz-size" }, [
                _vm._v(_vm._s(_vm.formatFileSize(file.size))),
              ]),
              _c("div", { staticClass: "dz-filename" }, [
                _vm._v(_vm._s(file.name)),
              ]),
            ]),
            _c("div", { staticClass: "dz-progress" }, [
              _c("span", {
                staticClass: "dz-upload",
                style: { width: file.upload.progress + "%" },
              }),
            ]),
            file.status === "error"
              ? _c("div", [
                  _c("div", { staticClass: "dz-error-message" }, [
                    _vm._v(_vm._s(file.errorMessage)),
                  ]),
                  _c("div", { staticClass: "dz-error-mark" }, [_vm._v("✗")]),
                ])
              : _vm._e(),
            file.status === "success"
              ? _c("div", { staticClass: "dz-success-mark" }, [_vm._v("✓")])
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dz-message" }, [
      _c("button", { staticClass: "dz-button", attrs: { type: "button" } }, [
        _vm._v(" Drop files here or click to upload "),
        _c("br"),
        _c("small", [
          _vm._v(
            " Accepted files: Images, PDF, Excel, Word, ZIP, HEIC, WebP (Max 50MB) "
          ),
          _c("br"),
          _vm._v("Up to 15 files allowed "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }