var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.notes,
      "sort-desc": _vm.sortDesc,
    },
    scopedSlots: _vm._u([
      {
        key: "item.report_include",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("v-checkbox", {
              on: {
                change: function (e) {
                  return _vm.reportInclude(e, item)
                },
              },
              model: {
                value: item.report_flag,
                callback: function ($$v) {
                  _vm.$set(item, "report_flag", $$v)
                },
                expression: "item.report_flag",
              },
            }),
          ]
        },
      },
      {
        key: "item.case_attachments",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              " " +
                _vm._s(
                  item.case_attachments ? item.case_attachments.length : 0
                ) +
                " "
            ),
          ]
        },
      },
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c("v-toolbar-title", [_vm._v("Notes")]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-2",
                    attrs: { icon: "", color: "green" },
                    on: { click: _vm.refreshNotes },
                  },
                  [_c("v-icon", [_vm._v("mdi-refresh")])],
                  1
                ),
                _c(
                  "div",
                  {
                    attrs: { id: "inverter_btn" },
                    on: {
                      click: function ($event) {
                        return _vm.openWindow(
                          "/sites/" + _vm.site_id + "/inverters_list"
                        )
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mt-1",
                      attrs: {
                        src: require("@/assets/img/inverters-link.svg"),
                        width: "24",
                        height: "24",
                      },
                    }),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      elevation: "0",
                      color: "transparent",
                      size: "28",
                      id: "native_link_btn",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.openWindow(_vm.nativeLink)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/" + _vm.system + ".png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _vm.site_plans
                              ? _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      attrs: {
                                        id: "floor_plan_btn",
                                        size: "28",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openWindow(_vm.site_plans)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_vm._v("mdi-floor-plan")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [_c("span", [_vm._v("View Site Plans")])]
                ),
                _c("v-divider", {
                  staticClass: "mx-4",
                  attrs: { inset: "", vertical: "" },
                }),
                _c(
                  "v-dialog",
                  {
                    attrs: { width: "500" },
                    model: {
                      value: _vm.showUploadModal,
                      callback: function ($$v) {
                        _vm.showUploadModal = $$v
                      },
                      expression: "showUploadModal",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-spacer",
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: { type: "success", dismissible: "" },
                                model: {
                                  value: _vm.showUploadSuccessAlert,
                                  callback: function ($$v) {
                                    _vm.showUploadSuccessAlert = $$v
                                  },
                                  expression: "showUploadSuccessAlert",
                                },
                              },
                              [
                                _vm._v(
                                  " Attachment was successfully uploaded. "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("v-card-title", [_vm._v("Upload Attachment")]),
                        _c(
                          "v-card-text",
                          [
                            _c("Dropzone", {
                              ref: "uploadDropzone",
                              attrs: { options: _vm.dropzoneOptions },
                              on: {
                                change: _vm.handleDropzoneChange,
                                error: _vm.handleDropzoneError,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-default\n                bg-gradient-danger\n                shadow-danger\n              ",
                                attrs: {
                                  ripple: false,
                                  elevation: 0,
                                  outlined: "",
                                },
                                on: { click: _vm.closeUpload },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-success\n                bg-gradient-success\n                shadow-default\n              ",
                                attrs: {
                                  ripple: false,
                                  elevation: 0,
                                  disabled: !_vm.uploadFiles.length,
                                },
                                on: { click: _vm.submitUpload },
                              },
                              [_vm._v(" Submit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "640px" },
                    model: {
                      value: _vm.dialogAttachment,
                      callback: function ($$v) {
                        _vm.dialogAttachment = $$v
                      },
                      expression: "dialogAttachment",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v("Attachments"),
                          ]),
                        ]),
                        _c(
                          "v-card-title",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-default\n                bg-gradient-success\n                shadow-success\n              ",
                                attrs: { ripple: false, elevation: 0 },
                                on: { click: _vm.showDialogAttachment },
                              },
                              [_vm._v("New")]
                            ),
                          ],
                          1
                        ),
                        _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.attachment_headers,
                            items: _vm.attachments,
                            "sort-by": "file",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item.name",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: item.file },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openWindow(item.file)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.attachment_actions",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAttachment(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-delete ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      disabled:
                                        _vm.user.claims.user_role ==
                                        "customer_view",
                                      color: "primary",
                                      dark: "",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" New Note ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.dialog,
                      callback: function ($$v) {
                        _vm.dialog = $$v
                      },
                      expression: "dialog",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "text-h5" }, [
                            _vm._v(_vm._s(_vm.formTitle)),
                          ]),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "12",
                                          md: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "Mentionable",
                                          {
                                            attrs: {
                                              keys: ["@"],
                                              items: _vm.items,
                                              offset: "6",
                                              "insert-space": "",
                                            },
                                            on: {
                                              open: _vm.onOpen,
                                              apply: _vm.onApply,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "no-result",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "dim" },
                                                      [_vm._v(" No result ")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "item-@",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "user" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.value) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("v-textarea", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                rows: "1",
                                                outlined: "",
                                                "auto-grow": "",
                                                placeholder: "Note",
                                              },
                                              on: { input: _vm.handleInput },
                                              model: {
                                                value: _vm.editedItem.note,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "note",
                                                    $$v
                                                  )
                                                },
                                                expression: "editedItem.note",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "12",
                                          md: "12",
                                        },
                                      },
                                      [
                                        _c("Dropzone", {
                                          ref: "dropzone",
                                          attrs: {
                                            options: _vm.dropzoneOptions,
                                          },
                                          on: {
                                            change: _vm.handleDropzoneChange,
                                            error: _vm.handleDropzoneError,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-default\n                bg-gradient-danger\n                shadow-danger\n              ",
                                attrs: {
                                  ripple: false,
                                  elevation: 0,
                                  text: "",
                                },
                                on: { click: _vm.close },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-success\n                bg-gradient-success\n                shadow-default\n              ",
                                attrs: {
                                  ripple: false,
                                  elevation: 0,
                                  text: "",
                                },
                                on: { click: _vm.save },
                              },
                              [_vm._v(" Save ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500px" },
                    model: {
                      value: _vm.dialogDelete,
                      callback: function ($$v) {
                        _vm.dialogDelete = $$v
                      },
                      expression: "dialogDelete",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "text-h5" }, [
                          _vm._v("Are you sure you want to delete this item?"),
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-default\n                bg-gradient-danger\n                shadow-danger\n              ",
                                attrs: { ripple: false, elevation: 0 },
                                on: { click: _vm.closeDelete },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                font-weight-bold\n                text-xs\n                btn-default\n                bg-gradient-default\n                shadow-default\n              ",
                                attrs: {
                                  ripple: false,
                                  elevation: 0,
                                  text: "",
                                },
                                on: { click: _vm.deleteItemConfirm },
                              },
                              [_vm._v("OK")]
                            ),
                            _c("v-spacer"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.actions",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-icon",
              {
                staticClass: "mr-2",
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    return _vm.editItem(item)
                  },
                },
              },
              [_vm._v(" mdi-pencil ")]
            ),
            _c(
              "v-icon",
              {
                staticClass: "mr-2",
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    return _vm.showAttachments(item)
                  },
                },
              },
              [_vm._v(" mdi-paperclip ")]
            ),
            _c(
              "v-icon",
              {
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    return _vm.deleteItem(item)
                  },
                },
              },
              [_vm._v(" mdi-delete ")]
            ),
          ]
        },
      },
      {
        key: "no-data",
        fn: function () {
          return [_vm._v(" There is no data available ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }